import React from 'react';
import './Sidebar.css';

function Sidebar({ selectedNode }) {
  if (!selectedNode) {
    return <div className="sidebar"></div>;
  }

  return (
    <div className="sidebar">
      <h2>{selectedNode.name}</h2>
      <div className="sidebar-section">
        <h3>Description</h3>
        <pre>{selectedNode.description}</pre>
      </div>
      {selectedNode.result && (
        <div className="sidebar-section">
          <h3>Result</h3>
          <pre>{selectedNode.result}</pre>
        </div>
      )}
    </div>
  );
}

export default Sidebar;