import React, { useCallback, useState } from 'react';
import './ObjectiveForm.css';

function ObjectiveForm({ modelNames, onObjectiveSubmit }) {
  const [inputObjective, setInputObjective] = useState('');
  const [selectedModel, setSelectedModel] = useState(modelNames[0] || '');

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    onObjectiveSubmit(inputObjective, selectedModel || modelNames[0]);
  }, [inputObjective, selectedModel, onObjectiveSubmit, modelNames]);

  return (
    <div className="objective-form">
      <h2>Objective</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          name="objective"
          value={inputObjective}
          onChange={(e) => setInputObjective(e.target.value)}
          placeholder="Enter a task that the model should think through…"
          rows={4}
        />
        <div className="form-actions">
          <select
            name="model"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
          >
            {modelNames.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
          <button type="submit">Start Reasoning</button>
        </div>
      </form>
      <div className="explanation-text">
        <p>
          <b>What is this?</b><br />
          This is an efficient reasoning system that works with different language models.
          You can watch it think in real-time before it writes the final answer. <br />
          There is no authentication required, but please note that rate limits apply.
          If a request fails, please try again with a different model or wait a short while and try again.
        </p>
      </div>
    </div>
  );
}

export default ObjectiveForm;