import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const ThoughtTree = forwardRef(function ThoughtTree({ thoughts, onNodeSelect }, ref) {
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);

  const nodeWidth = 200; // Adjust based on node size
  const nodeHeight = 40; // Adjust based on node size

  useImperativeHandle(ref, () => ({
    scrollToNode(nodeId) {
      const element = document.getElementById(`node-${nodeId}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );

        if (!isVisible) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    },
  }));

  useEffect(() => {
    if (thoughts.length === 0) return;

    const columns = [];

    function assignPositions(node, columnIndex, columns, parent) {
      if (!columns[columnIndex]) {
        columns[columnIndex] = [];
      }

      const verticalSpacing = nodeHeight + 20; // Adjust as needed
      const horizontalSpacing = nodeWidth + 50; // Adjust as needed

      // Determine y position
      let y = 0;
      const previousNodeInColumn = columns[columnIndex][columns[columnIndex].length - 1];
      const lastNodeInNextColumn =
        columns[columnIndex + 1] &&
        columns[columnIndex + 1][columns[columnIndex + 1].length - 1];

      if (lastNodeInNextColumn) {
        y = lastNodeInNextColumn.y + verticalSpacing;
      } else if (previousNodeInColumn) {
        y = previousNodeInColumn.y + verticalSpacing;
      } else if (parent) {
        y = parent.y;
      } else {
        y = 2;
      }

      // Adding a small offset to the x and y positions to keep it away from the edge of the SVG
      // so borders don't get cut off
      node.x = columnIndex * horizontalSpacing + 2;
      node.y = y;

      columns[columnIndex].push(node);

      // Process children
      node.children &&
        node.children.forEach(child => {
          assignPositions(child, columnIndex + 1, columns, node);
        });
    }

    const rootNode = thoughts[0]; // Assuming thoughts[0] is the root
    assignPositions(rootNode, 0, columns, null);

    // Flatten nodes
    const allNodes = columns.flat();

    // Build links
    const allLinks = [];
    allNodes.forEach(node => {
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          allLinks.push({ source: node, target: child });
        });
      }
    });

    setNodes(allNodes);
    setLinks(allLinks);
  }, [thoughts]);

  // Function to compute the path for links
  function computeLinkPath(link) {
    const { source, target } = link;
    const startX = source.x + nodeWidth;
    const startY = source.y + nodeHeight / 2;
    const endX = target.x;
    const endY = target.y + nodeHeight / 2;

    if (startY === endY) {
      // Straight line
      return `M${startX},${startY} L${endX},${endY}`;
    } else {
      // Line with right angles
      const midX = (startX + endX) / 2;
      return `M${midX},${startY} H${midX} V${endY} H${endX}`;
    }
  }

  return (
    <div className="thought-tree">
      <svg
        width="100%"
        height="100%"
        style={{
          minWidth: nodes.reduce((max, node) => Math.max(max, node.x + nodeWidth), 0),
          minHeight: nodes.reduce((max, node) => Math.max(max, node.y + nodeHeight), 0),
        }}
      >
        {/* Draw links */}
        {links.map((link, index) => (
          <path
            key={`link-${index}`}
            className={["link", link.source.status].join(" ")}
            d={computeLinkPath(link)}
            fill="none"
            strokeWidth={1}
            // Animation for drawing the line
            style={{
              animation: 'fadeIn .5s forwards',
            }}
          />
        ))}

        {/* Draw nodes */}
        {nodes.map((node, index) => (
          <g
            key={`node-${node.id}`}
            id={`node-${node.id}`}
            className={["node", node.status].join(" ")}
            transform={`translate(${node.x}, ${node.y})`}
            onClick={() => onNodeSelect && onNodeSelect(node)}
            // Fade-in animation
            style={{
              opacity: 0,
              animation: 'fadeIn .5s forwards',
              cursor: 'pointer',
            }}
          >
            <rect width={nodeWidth} height={nodeHeight} fill="#ADD8E6" rx={5} ry={5} />
            <text x={nodeWidth/2} y={nodeHeight/2+5} textAnchor="middle">
              {node.name.length > 24 ? node.name.substring(0, 24) + "…" : node.name}
            </text>
            <title>{node.description}</title>
          </g>
        ))}

        {/* Define animations */}
        <style>
          {`
            @keyframes fadeIn {
              to {
                opacity: 1;
              }
            }
          `}
        </style>
      </svg>
    </div>
  );
});

export default ThoughtTree;