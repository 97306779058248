import React from 'react';
import './TopBar.css';

function TopBar() {
  return (
    <div className="top-bar">
      <h1>Solver2</h1>
      <h2>Tech preview</h2>
    </div>
  );
}

export default TopBar;