import React, { useEffect, useState, useMemo, useRef } from 'react';
import API from './api';
import ThoughtView from './ThoughtView';
import TopBar from './TopBar';
import ObjectiveForm from './ObjectiveForm';
import FinalResult from './FinalResult';
import './App.css';

function App() {
  const api = useMemo(() => new API(process.env.REACT_APP_API_URL), []);

  const [serverDetails, setServerDetails] = useState({ models: [] });
  const [currentView, setCurrentView] = useState('objective'); // 'objective', 'reasoning', 'result'
  const [currentlyReasoning, setCurrentlyReasoning] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [thoughts, setThoughts] = useState([]);
  const [objective, setObjective] = useState('');
  const [result, setResult] = useState('');
  const treeRef = useRef(null);

  const createNode = (id, name, description) => ({
    id,
    name,
    description,
    children: [],
    status: 'in-progress'
  });

  const addNode = (parentId, newNode) => {
    setThoughts(prevThoughts => {
      if (prevThoughts.length === 0) {
        return [newNode];
      }

      const updateNode = (node) => {
        if (node.id === parentId) {
          if (node.children.some(child => child.id === newNode.id)) {
            return node;
          }
          return { ...node, children: [...node.children, newNode] };
        }
        return { ...node, children: node.children.map(updateNode) };
      };
      return prevThoughts.map(updateNode);
    });
  };

  const completeNode = (id, result) => {
    setThoughts(prevThoughts => {
      // Important, since sometimes not all nodes are reported completed.
      const recursivelyMarkCompleted = (node) => {
        return { ...node, status: 'completed', children: node.children.map(recursivelyMarkCompleted) };
      };
      const updateNode = (node) => {
        if (node.id === id) {
          setSelectedNode(node);
          if (treeRef.current) {
            treeRef.current.scrollToNode(node.id);
          }
          return { ...recursivelyMarkCompleted(node), result: result };
        }
        return { ...node, children: node.children.map(updateNode) };
      };
      return prevThoughts.map(updateNode);
    });
  };

  const startReasoning = async (objective, modelName) => {
    setCurrentlyReasoning(true);
    await api.startReasoning(objective, modelName, (event, data) => {
      if (event === 'task_created') {
        console.debug("Task created: ", data);
        addNode(data.parent_id, createNode(data.id, data.title, data.problem));
      } else if (event === 'task_completed') {
        console.debug("Task completed: ", data);
        completeNode(data.id, data.result);
      } else if (event === 'result_token') {
        setCurrentlyReasoning(false);
        setCurrentView('result');
        setResult(prevResult => prevResult + data);
      } else {
        console.debug("Unhandled SSE event: ", event, " data: ", data)
      }
    });
  };

  const handleObjectiveSubmit = (submittedObjective, modelName) => {
    setObjective(submittedObjective);
    setCurrentView('reasoning');
    startReasoning(submittedObjective, modelName).catch(error => window.alert(error.message));
  };

  useEffect(() => {
    api.getServerConfig().then(data => {
      setServerDetails(data);
    }).catch(error => {
      console.error('Failed to fetch server config:', error);
      window.alert('Failed to fetch server config:', error);
    });
  }, [api]);

  const views = ['objective', 'reasoning', 'result'];

  return (
    <div className="App">
      <TopBar />
      <div className="content-container">
        <div className={`view-slider ${currentView}`}>
          <ObjectiveForm onObjectiveSubmit={handleObjectiveSubmit} modelNames={serverDetails.models} />
          <ThoughtView
            thoughts={thoughts}
            currentlyReasoning={currentlyReasoning}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            treeRef={treeRef}
          />
          <FinalResult objective={objective} result={result} />
        </div>
        <div className={["view-controls", thoughts.length === 0 || currentlyReasoning ? "hidden" : ""].join(" ")}>
          {views.map((view, index) => (
            <button
              key={view}
              className={`view-dot ${currentView === view ? 'active' : ''}`}
              onClick={() => setCurrentView(view)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;