import React, { useState } from 'react';
import './FinalResult.css';

function FinalResult({ objective, result }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(result).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  return (
    <div className="final-result">
      <h2>Reasoning Complete</h2>
      <p className="objective-text">{objective}</p>
      <div className="result-container">
        <button className="copy-button" onClick={copyToClipboard}>
            {copySuccess ? 'Copied' : 'Copy to Clipboard'}
        </button>
        <p>{result}</p>
      </div>
    </div>
  );
}

export default FinalResult;