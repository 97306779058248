import React from 'react';
import ThoughtTree from './ThoughtTree';
import Sidebar from './Sidebar';
import './ThoughtView.css';

function ThoughtView({ thoughts, currentlyReasoning, selectedNode, setSelectedNode, treeRef }) {
  return (
    <div className={`main-content ${currentlyReasoning ? "reasoning" : ""}`}>
      <ThoughtTree ref={treeRef} thoughts={thoughts} onNodeSelect={currentlyReasoning ? null : setSelectedNode} />
      <Sidebar selectedNode={selectedNode} />
    </div>
  );
}

export default ThoughtView;