class API {
  constructor(baseUrl) {
    this.baseUrl = baseUrl.replace(/\/$/, '');
  }

  async getServerConfig() {
    const response = await fetch(`${this.baseUrl}/config`);
    if (!response.ok) {
      throw new Error('Failed to fetch available models');
    }
    return response.json();
  }

  async startReasoning(objective, model, onEvent) {
    try {
      const response = await fetch(`${this.baseUrl}/reason`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ objective, model }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let lastEventName = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop();

        for (const line of lines) {
          if (line.trim() === '') continue;

          const colonIndex = line.indexOf(':');

          if (colonIndex === -1) {
            console.debug("Skipping line in SSE stream with no colon: ", line)
            continue;
          }

          const eventName = line.slice(0, colonIndex).trim();
          const data = line.slice(colonIndex + 1).trim();

          if (eventName === 'event') {
            lastEventName = data;
          } else if (eventName === 'data') {
            onEvent(lastEventName, JSON.parse(data));
          } else {
            console.debug("Unknown type of line in SSE stream: ", line)
          }
        }
      }

      if (buffer) {
        const data = JSON.parse(buffer);
        onEvent('resultToken', data.token);
      }

    } catch (error) {
      console.error('Error in startReasoning:', error);
      throw error;
    }
  }
}

export default API;
